
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { cardService } from '@/services/card.service';
import { Card } from '@/api-domain/card';

@Options({
  emits: ['created'],
})
export default class EzAddCard extends Vue {
  @Prop({ isRequired: true }) packId!: string;

  front = '';

  back = '';

  info = '';

  showForm = false;

  isSaving = false;

  frontError = '';

  backError = '';

  onAddClick(): void {
    this.showForm = true;
    setTimeout(() => {
      (this.$refs.cardFrontRef as HTMLInputElement).focus();
    }, 0);
  }

  onCancelClick(): void {
    this.showForm = false;
  }

  validateFront(): void {
    this.frontError = !this.front.trim() ? 'Front should be filled' : '';
  }

  validateBack(): void {
    this.backError = !this.front.trim() ? 'Back should be filled' : '';
  }

  clearData(): void {
    this.front = '';
    this.back = '';
    this.info = '';
  }

  async handleSubmit(): Promise<void> {
    this.validateFront();
    this.validateBack();

    if (this.frontError || this.backError) {
      return;
    }
    this.isSaving = true;
    const newCard: Omit<Card, 'packId'> = {
      front: this.front,
      back: this.back,
      info: this.info,
    };
    try {
      const createdCard = await cardService.create(this.packId, newCard);
      this.$emit('created', createdCard);
      this.clearData();
      this.isSaving = false;
      this.showForm = false;
    } catch {
      this.isSaving = false;
    }
  }
}
