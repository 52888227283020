/* eslint-disable no-lonely-if */
import { Card } from '@/api-domain/card';

export enum ParseErrorValue {
  NOTHING_TO_IMPORT = 'Nothing to import',
  ALL_CARDS_EXIST = 'All cards exists'
}
type ParseError = ParseErrorValue | null;
type UploadCard = Omit<Card, 'packId'>;
type ExistingCard = UploadCard & { cardInPack: Card };
type ParsedFile = {
  uploadNewCards: UploadCard[];
  existingCards: ExistingCard[];
  conflictingCards: UploadCard[];
  error: ParseError;
}

function isTsv(content: string) {
  return content.includes('\t');
}

class ImportService {
  private findCardExistingInTheDeck(
    front: string, back: string, cardsInThePack: Card[],
  ): Card | null {
    return (
      cardsInThePack?.find((card) => card.front === front || card.back === back)
      || null
    );
  }

  private isExactlyTheSame(card: UploadCard, cardInPack: Card) {
    return card.back === cardInPack.back
      && card.front === cardInPack.front
      && card.info === cardInPack.info;
  }

  private replaceStupidChars(txt: string): string {
    // eslint-disable-next-line no-irregular-whitespace
    return txt.replace(/’/g, '\'').replace(/ /g, ' ');
  }

  private hasDuplicatesInCardsArray(cardToCheck: UploadCard, allCards: UploadCard[]) {
    const countSameCards = allCards.reduce((count: number, card: UploadCard) => (
      (cardToCheck.front === card.front || cardToCheck.back === card.back)
        ? count + 1
        : count
    ), 0);
    return countSameCards > 1;
  }

  private txtToCards(txt: string, reverse: boolean): UploadCard[] {
    const lines = this.replaceStupidChars(txt).split(/\r?\n/)
      .map((item) => item.trim());

    const result = lines.reduce((acc: string[][], item) => {
      if (item === '') {
        const newGroup: string[] = [];
        // Start a new group if we encounter an empty string
        acc.push(newGroup);
      } else {
        // Add the item to the last group in the accumulator
        if (acc.length === 0) {
          // If no groups exist yet, create the first group
          acc.push([item]);
        } else {
          acc[acc.length - 1].push(item);
        }
      }
      return acc;
    }, []);

    // Clean up empty groups that might be at the end
    const cleanedResult = result.filter((group) => group.length > 0);

    const cards: UploadCard[] = cleanedResult.map((cardInfo) => {
      const card = reverse ? {
        back: cardInfo[0],
        front: cardInfo[1] || '',
        info: cardInfo[2] || '',
      } : {
        front: cardInfo[0],
        back: cardInfo[1] || '',
        info: cardInfo[2] || '',
      };
      return card;
    });

    // let i = 0;
    // const cards: UploadCard[] = [];
    // let card: UploadCard;

    // while (i < lines.length) {
    //   card = reverse ? {
    //     back: lines[i],
    //     front: lines[i + 1] || '',
    //     info: lines[i + 2] || '',
    //   } : {
    //     front: lines[i],
    //     back: lines[i + 1] || '',
    //     info: lines[i + 3] || '',
    //   };
    //   cards.push(card);
    //   i += 3;
    // }
    return cards;
  }

  private txtToCardsOld(txt: string, reverse: boolean): UploadCard[] {
    const lines = this.replaceStupidChars(txt).split(/\r?\n/)
      .filter((item) => item !== '')
      .map((item) => item.trim());
    console.log('>>>', lines);
    let i = 0;
    const cards: UploadCard[] = [];
    let card: UploadCard;

    while (i < lines.length) {
      card = reverse ? {
        back: lines[i],
        front: lines[i + 1] || '',
        info: lines[i + 2] || '',
      } : {
        front: lines[i],
        back: lines[i + 1] || '',
        info: lines[i + 3] || '',
      };
      cards.push(card);
      i += 3;
    }
    return cards;
  }

  private tsvToCards(content: string, reverse: boolean): UploadCard[] {
    const sanitisedContent = content.replaceAll('\r', '');
    const lines = sanitisedContent.split('\n');
    const cards: UploadCard[] = lines.map((line) => {
      const values = line.split('\t');
      return {
        back: reverse ? values[1] : values[0],
        front: reverse ? values[0] : values[1],
        info: values[2] || '',
      };
    });
    return cards;
  }

  findConflictingCards(uploadNewCards: UploadCard[]): UploadCard[] {
    return uploadNewCards.reduce((conflictingCards: UploadCard[], card) => {
      if (this.hasDuplicatesInCardsArray(card, uploadNewCards)) {
        return [...conflictingCards, card];
      }
      return conflictingCards;
    }, []);
  }

  parseTxt(txtOrTsv: string, reverse: boolean, cardsInThePack: Card[]): ParsedFile {
    const cardsInImportfile = isTsv(txtOrTsv)
      ? this.tsvToCards(txtOrTsv, reverse)
      : this.txtToCards(txtOrTsv, reverse);

    if (cardsInImportfile.length === 0) {
      return {
        uploadNewCards: [],
        existingCards: [],
        conflictingCards: [],
        error: ParseErrorValue.NOTHING_TO_IMPORT,
      };
    }

    const uploadNewCards: UploadCard[] = [];
    const existingCards: ExistingCard[] = [];
    console.log(cardsInImportfile);
    cardsInImportfile.forEach((card) => {
      const cardInTheDeck = this.findCardExistingInTheDeck(card.front, card.back || '', cardsInThePack);

      if (cardInTheDeck) {
        if (!this.isExactlyTheSame(card, cardInTheDeck)) {
          existingCards.push({ ...card, cardInPack: cardInTheDeck });
        }
      } else {
        uploadNewCards.push(card);
      }
    });

    if (uploadNewCards.length === 0 && existingCards.length === 0) {
      return {
        uploadNewCards: [],
        existingCards: [],
        conflictingCards: [],
        error: ParseErrorValue.ALL_CARDS_EXIST,
      };
    }

    return {
      uploadNewCards,
      existingCards,
      conflictingCards: this.findConflictingCards(uploadNewCards),
      error: null,
    };
  }
}

export const importService = new ImportService();
