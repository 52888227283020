
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { omit } from 'lodash-es';
import { Card } from '@/api-domain/card';
import { cardService } from '@/services/card.service';
import { Pack } from '@/api-domain/pack';
import { packService } from '@/services/pack.service';
import EzEditCardModal from '@/components/cards/EzEditCardModal.vue';
import { importService } from '@/services/import.service';

export enum ParseErrorValue {
  NOTHING_TO_IMPORT = 'Nothing to import',
  ALL_CARDS_EXIST = 'All cards exists'
}
type ParseError = ParseErrorValue | null;
type UploadCard = Omit<Card, 'packId'>;
type ExistingCard = UploadCard & { cardInPack: Card };
type ParsedFile = {
  uploadNewCards: UploadCard[];
  existingCards: ExistingCard[];
  conflictingCards: UploadCard[];
  error: ParseError;
}

@Options({
  components: {
    EzEditCardModal,
  },
})
export default class ImportCards extends Vue {
  @Prop({ isRequired: true }) packId!: string;

  initialised = false;

  uploadNewCards: UploadCard[] = [];

  // Cards are existing, but the user clicked on overwrite
  uploadUpdatingCards: UploadCard[] = [];

  existingCards: ExistingCard[] = [];

  conflictingCards: UploadCard[] = [];

  cardsInThePack: Card[] = [];

  pack: Pack | null = null;

  file: File | null = null;

  success = false;

  uploadError = false;

  errorMessage = '';

  editingCard: UploadCard | null = null;

  reverse = false;

  isFileInputPrimary(): boolean {
    return !this.success && !this.file;
  }

  isUploadDisabled(): boolean {
    return (this.uploadNewCards.length === 0 && this.uploadUpdatingCards.length === 0)
      || this.existingCards.length > 0;
  }

  isUploadPrimary(): boolean {
    return this.uploadNewCards.length > 0
      || this.uploadUpdatingCards.length > 0
      || this.existingCards.length > 0;
  }

  isGoToPacksDisabled(): boolean {
    return !this.success;
  }

  isGoToPacksPrimary(): boolean {
    return this.success;
  }

  async created(): Promise<void> {
    this.initialise();
  }

  async initialise(): Promise<void> {
    this.cardsInThePack = await cardService.getAll(this.packId);
    this.pack = await packService.getPack(this.packId);
    this.initialised = true;
  }

  private pickFrontAndBackAndInfo(cards: UploadCard[]): UploadCard[] {
    return cards.map(({ front, back, info }) => ({ front, back, info }));
  }

  private mapParseErrorToString(error: ParseError): string {
    if (error === null) {
      return '';
    }

    const errorMessages: Record<ParseErrorValue, string> = {
      [ParseErrorValue.ALL_CARDS_EXIST]: 'All cards exist',
      [ParseErrorValue.NOTHING_TO_IMPORT]: 'Nothing to import',
    };

    return errorMessages[error];
  }

  getFile(): File | null {
    const fileInput = this.$refs.fileInputRef as HTMLInputElement;
    if (!fileInput.files || !fileInput.files[0]) {
      return null;
    }
    return fileInput.files[0];
  }

  private importFile(file: File): Promise<ParsedFile> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        if (e?.target?.result && typeof e.target.result === 'string') {
          const parsed = importService.parseTxt(e.target.result, this.reverse, this.cardsInThePack);
          if (parsed.error === null) {
            resolve(parsed);
          }
          reject(new Error(this.mapParseErrorToString(parsed.error)));
        }
        reject(new Error('No content'));
      };

      reader.readAsText(file);
    });
  }

  private convertExistingCardToUploadCard(existingCard: ExistingCard): UploadCard {
    return {
      id: existingCard.cardInPack.id,
      ...omit(existingCard, 'cardInPack'),
    };
  }

  onEditConflictClick(card: UploadCard): void {
    this.editingCard = card;
  }

  onEditConflictCanceled(): void {
    this.editingCard = null;
  }

  onEditConflictUpdated(newValues: { front: string, back: string}): void {
    if (!this.editingCard) {
      return;
    }
    this.editingCard.front = newValues.front;
    this.editingCard.back = newValues.back;
    this.editingCard = null;
    this.conflictingCards = importService.findConflictingCards(this.uploadNewCards);
  }

  onUpdateClick(card: ExistingCard): void {
    if (this.uploadUpdatingCards) {
      this.uploadUpdatingCards.push(this.convertExistingCardToUploadCard(card));
    }
    if (this.existingCards) {
      this.existingCards.splice(this.existingCards.indexOf(card), 1);
    }
  }

  onSkipClick(card: ExistingCard): void {
    if (this.existingCards) {
      this.existingCards.splice(this.existingCards.indexOf(card), 1);
    }
  }

  onSkipConflictingClick(card: UploadCard): void {
    const index = this.uploadNewCards.findIndex(
      (uploadCard) => uploadCard.front === card.front && uploadCard.back === card.back,
    );
    if (!index) {
      return;
    }
    this.uploadNewCards.splice(index, 1);
    this.conflictingCards = importService.findConflictingCards(this.uploadNewCards);
  }

  onUpdateAllClick(): void {
    this.uploadUpdatingCards.push(
      ...this.existingCards.map((card) => this.convertExistingCardToUploadCard(card)),
    );
    this.existingCards = [];
  }

  onSkipAllClick(): void {
    this.existingCards = [];
  }

  async onFileInputChange(): Promise<void> {
    this.file = this.getFile();
    if (this.file) {
      this.uploadNewCards = [];
      this.existingCards = [];
      this.conflictingCards = [];
      try {
        const parsed = await this.importFile(this.file);
        this.uploadNewCards = parsed.uploadNewCards;
        this.existingCards = parsed.existingCards;
      } catch (e) {
        if (e instanceof Error) {
          this.errorMessage = e.message;
        }
        this.file = null;
      }
    }
  }

  async onApplyClick(): Promise<void> {
    if (this.uploadNewCards.length === 0 && this.uploadUpdatingCards.length === 0) {
      return;
    }

    try {
      if (this.uploadNewCards.length > 0) {
        await cardService.createBatch(
          this.packId,
          this.pickFrontAndBackAndInfo(this.uploadNewCards),
        );
      }
      if (this.uploadUpdatingCards.length > 0) {
        await cardService.updateBatch(this.packId, this.uploadUpdatingCards);
      }
      this.success = true;
      this.uploadNewCards = [];
      this.existingCards = [];
      this.file = null;
    } catch {
      this.uploadError = true;
      this.errorMessage = 'Cannot upload cards';
    }
  }

  onGoToPackClick(): void {
    this.$router.push(`/packs/${this.packId}/cards`);
  }

  onReverseClick(): void {
    this.reverse = !this.reverse;
    this.onFileInputChange();
  }
}
