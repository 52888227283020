
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
  emits: ['updated', 'canceled'],
})
export default class EzEditCard extends Vue {
  @Prop({ isRequired: true }) front!: string;

  @Prop({ isRequired: true }) back!: string;

  editingFront = '';

  editingBack = '';

  isSaving = false;

  frontError = '';

  backError = '';

  created(): void {
    this.editingFront = this.front;
    this.editingBack = this.back;
    setTimeout(() => {
      (this.$refs.cardFrontRef as HTMLInputElement).focus();
    }, 0);
  }

  onCancelClick(): void {
    this.$emit('canceled');
  }

  validateFront(): void {
    this.frontError = !this.editingFront.trim() ? 'Front should be filled' : '';
  }

  validateBack(): void {
    this.backError = !this.editingFront.trim() ? 'Back should be filled' : '';
  }

  async handleSubmit(): Promise<void> {
    this.validateFront();

    this.validateBack();

    if (this.frontError || this.backError) {
      return;
    }
    this.isSaving = true;
    try {
      this.$emit('updated', { front: this.editingFront, back: this.editingBack });
      this.isSaving = false;
    } catch {
      this.isSaving = false;
    }
  }
}
