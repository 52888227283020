<template>
  <div class="card-edit">
    <div class="field has-addons">
      <button class="button is-primary mr-2" v-on:click="onEditClick()">Edit card</button>
    </div>
  </div>
  <div @keydown.esc="onCancelClick()" class="modal" :class="{ 'is-active': showForm }">
    <div v-on:click="onCancelClick()" class="modal-background"></div>
      <div class="modal-content">
        <div class="box">
          <div class="notification is-info is-size-7">
            If the card contains some context,
            the main text should be surrounded with square brackets. <br><br>
            E.g.:
            This is the context [this is the main content], and some more context here.
            <br><br>
            If the card only contains main text, no need to use any square brackets.
          </div>
          <form @submit.prevent="handleSubmit">
            <div class="field">
              <label class="label">Front</label>
              <div class="control">
                <textarea
                  rows="3"
                  class="textarea"
                  type="text"
                  ref="cardFrontRef"
                  placeholder="E.g. ez egy mondat"
                  v-model="front"
                  :disabled="isSaving"
                />
                <p v-if="frontError" class="help is-danger">{{ frontError }}</p>
              </div>
            </div>
            <div class="field">
              <label class="label">Back</label>
              <div class="control">
                <textarea
                  rows="3"
                  class="textarea"
                  type="text"
                  placeholder="E.g. This is a sentence"
                  v-model="back"
                  :disabled="isSaving"
                />
                <p v-if="frontError" class="help is-danger">{{ frontError }}</p>
              </div>
            </div>
            <div class="field">
              <label class="label">Info</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  placeholder=
                  "E.g. said by Marilyn Manson in 2002 when he had been kicked in the ass"
                  v-model="info"
                  :disabled="isSaving"
                />
                <p v-if="frontError" class="help is-danger">{{ frontError }}</p>
              </div>
            </div>
            <div class="field is-grouped">
              <div class="control">
                <button
                  type="submit"
                  class="button is-primary"
                  v-bind:class="isSaving ? 'is-loading' : ''"
                >
                  Save
                </button>
              </div>
              <div class="control">
                <button
                  type="button"
                  class="button"
                  v-if="!isSaving"
                  v-on:click="onCancelClick()"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { cardService } from '@/services/card.service';
import { Card } from '@/api-domain/card';

@Options({
  emits: ['updated'],
})
export default class EzEditCard extends Vue {
  @Prop({ isRequired: true }) card!: Card;

  front = '';

  back = '';

  info = '';

  showForm = false;

  isSaving = false;

  frontError = '';

  backError = '';

  onEditClick(): void {
    this.front = this.card.front;
    this.back = this.card.back;
    this.info = this.card.info;

    this.showForm = true;
    setTimeout(() => {
      (this.$refs.cardFrontRef as HTMLInputElement).focus();
    }, 0);
  }

  onCancelClick(): void {
    this.showForm = false;
  }

  validateFront(): void {
    this.frontError = !this.front.trim() ? 'Front should be filled' : '';
  }

  validateBack(): void {
    this.backError = !this.front.trim() ? 'Back should be filled' : '';
  }

  async handleSubmit(): Promise<void> {
    if (!this.card.id) {
      throw new Error('Missing id property');
    }

    this.validateFront();

    this.validateBack();

    if (this.frontError || this.backError) {
      return;
    }
    this.isSaving = true;
    const newCard: Omit<Card, 'packId'> = {
      front: this.front,
      back: this.back,
      info: this.info,
    };
    try {
      const createdCard = await cardService.update(this.card.id, newCard);
      this.$emit('updated', createdCard);
      this.isSaving = false;
      this.showForm = false;
    } catch {
      this.isSaving = false;
    }
  }
}
</script>
