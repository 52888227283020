<template>
  <h2 class="title is-3">{{ pack?.name }}</h2>
  <div>
    <router-link to="/">Back to the packs</router-link>
  </div>
  <div class="cards container pb-6">
    <div v-if="isFetched && cards.length === 0">
      <article class="message is-primary">
        <div class="message-body">
          This pack is empty
        </div>
      </article>
    </div>
    <div v-for="card in cards" v-bind:key="card.id" class="block card">
      <EzCard :card="card" :isSelected="selectedCard === card" @click="onCardClick(card)" />
      <button
        class="button swap"
        @click="onSwapClick(card)"
      >
        <font-awesome-icon icon="backward" />
      </button>
    </div>
  </div>
  <div class="buttons has-background-grey-light p-2">
    <div class="container is-flex">
      <EzAddCard :packId="packId" @created="onCardCreated"></EzAddCard>
      <button class="button is-info" v-on:click="onReverseClick()">Reverse Cards</button>
      <EzEditCard
        v-if="!!selectedCard"
        @updated="onCardUpdated"
        :card="selectedCard">
      </EzEditCard>
      <EzDeleteCard
        v-if="!!selectedCard"
        @deleted="onCardDeleted"
        :card="selectedCard">
      </EzDeleteCard>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import EzCard from '@/components/cards/EzCard.vue';
import EzAddCard from '@/components/cards/EzAddCard.vue';
import EzEditCard from '@/components/cards/EzEditCard.vue';
import EzDeleteCard from '@/components/cards/EzDeleteCard.vue';
import { Card } from '@/api-domain/card';
import { cardService } from '@/services/card.service';
import { packService } from '@/services/pack.service';
import { Pack } from '@/api-domain/pack';

@Options({
  components: {
    EzAddCard,
    EzEditCard,
    EzDeleteCard,
    EzCard,
  },
})
export default class Cards extends Vue {
  @Prop({ isRequired: true }) packId!: string;

  cards: Card[] = [];

  pack: Pack | null = null;

  selectedCard: Card | null = null;

  fetchError = false;

  isFetched = false;

  async fetchApi(): Promise<void> {
    try {
      this.cards = await cardService.getAll(this.packId);
      this.pack = await packService.getPack(this.packId);
      this.isFetched = true;
    } catch {
      this.fetchError = true;
    }
  }

  async created(): Promise<void> {
    await this.fetchApi();
  }

  async onCardCreated(): Promise<void> {
    await this.fetchApi();
  }

  async onCardUpdated(): Promise<void> {
    // Should be fetched, because the ordering logic is on the server side
    this.selectedCard = null;
    await this.fetchApi();
  }

  async onSwapClick(card: Card): Promise<void> {
    const cardToUpdate = this.cards.find((c) => c.id === card.id);
    if (!card.id || !cardToUpdate) {
      return;
    }
    try {
      await cardService.update(card.id, { ...card, front: card.back, back: card.front });
      const { front } = card;
      cardToUpdate.front = card.back;
      cardToUpdate.back = front;
    } catch {
      console.error('Error on swapping cards');
    }
  }

  async onCardDeleted(): Promise<void> {
    this.selectedCard = null;
    await this.fetchApi();
  }

  onCardClick(card: Card): void {
    if (this.selectedCard === card) {
      this.selectedCard = null;
    } else {
      this.selectedCard = card;
    }
  }

  async onReverseClick(): Promise<void> {
    const newCards = this.cards.map((card) => ({
      ...card,
      front: card.back,
      back: card.front,
    }));
    await cardService.updateBatch(this.packId, newCards);
    this.fetchApi();
  }
}
</script>
<style scoped lang="scss">
.buttons {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
}

.card {
  position: relative;
}

.swap {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
