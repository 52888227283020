
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Card } from '@/api-domain/card';
import EzCardText from './EzCardText.vue';
import { stringToCardText } from '@/helpers/stringToCardText';

@Options({
  components: {
    EzCardText,
  },
})
export default class EzCard extends Vue {
  @Prop({ required: true }) card!: Card;

  @Prop({ required: false }) isSelected = false;

  stringToCardText = stringToCardText;
}
