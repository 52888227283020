<template>
  <span
    v-for="(contentPart, index) in content"
    v-bind:key="index"
    v-bind:class="contentPart.isMain ? 'ez-card-text__main' : 'ez-card-text__context'">
    {{ contentPart.text }}
  </span>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { CardText } from '@/helpers/stringToCardText';

export default class EzCardText extends Vue {
  @Prop({ required: true }) content!: CardText;
}
</script>

<style lang="scss" scoped>
  .ez-card-text {
    &__context {
      opacity: 0.5;
    }
  }
</style>
