
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import EzCard from '@/components/cards/EzCard.vue';
import EzAddCard from '@/components/cards/EzAddCard.vue';
import EzEditCard from '@/components/cards/EzEditCard.vue';
import EzDeleteCard from '@/components/cards/EzDeleteCard.vue';
import { Card } from '@/api-domain/card';
import { cardService } from '@/services/card.service';
import { packService } from '@/services/pack.service';
import { Pack } from '@/api-domain/pack';

@Options({
  components: {
    EzAddCard,
    EzEditCard,
    EzDeleteCard,
    EzCard,
  },
})
export default class Cards extends Vue {
  @Prop({ isRequired: true }) packId!: string;

  cards: Card[] = [];

  pack: Pack | null = null;

  selectedCard: Card | null = null;

  fetchError = false;

  isFetched = false;

  async fetchApi(): Promise<void> {
    try {
      this.cards = await cardService.getAll(this.packId);
      this.pack = await packService.getPack(this.packId);
      this.isFetched = true;
    } catch {
      this.fetchError = true;
    }
  }

  async created(): Promise<void> {
    await this.fetchApi();
  }

  async onCardCreated(): Promise<void> {
    await this.fetchApi();
  }

  async onCardUpdated(): Promise<void> {
    // Should be fetched, because the ordering logic is on the server side
    this.selectedCard = null;
    await this.fetchApi();
  }

  async onSwapClick(card: Card): Promise<void> {
    const cardToUpdate = this.cards.find((c) => c.id === card.id);
    if (!card.id || !cardToUpdate) {
      return;
    }
    try {
      await cardService.update(card.id, { ...card, front: card.back, back: card.front });
      const { front } = card;
      cardToUpdate.front = card.back;
      cardToUpdate.back = front;
    } catch {
      console.error('Error on swapping cards');
    }
  }

  async onCardDeleted(): Promise<void> {
    this.selectedCard = null;
    await this.fetchApi();
  }

  onCardClick(card: Card): void {
    if (this.selectedCard === card) {
      this.selectedCard = null;
    } else {
      this.selectedCard = card;
    }
  }

  async onReverseClick(): Promise<void> {
    const newCards = this.cards.map((card) => ({
      ...card,
      front: card.back,
      back: card.front,
    }));
    await cardService.updateBatch(this.packId, newCards);
    this.fetchApi();
  }
}
