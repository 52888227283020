<template>
  <div class="card is-clickable" :class="{ 'has-background-primary': isSelected }">
    <div class="card-content">
      <p class="has-background-info-light">
        <EzCardText :content="stringToCardText(card.front)" />
      </p>
      <p class="has-background-success-light">
        <EzCardText :content="stringToCardText(card.back)" />
      </p>
      <p class="has-background-warning-light">
        {{ card.info }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Card } from '@/api-domain/card';
import EzCardText from './EzCardText.vue';
import { stringToCardText } from '@/helpers/stringToCardText';

@Options({
  components: {
    EzCardText,
  },
})
export default class EzCard extends Vue {
  @Prop({ required: true }) card!: Card;

  @Prop({ required: false }) isSelected = false;

  stringToCardText = stringToCardText;
}
</script>
