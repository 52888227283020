
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { cardService } from '@/services/card.service';
import { Card } from '@/api-domain/card';

@Options({
  emits: ['deleted'],
})
export default class EzDeleteCard extends Vue {
  @Prop({ isRequired: true }) card!: Card;

  showForm = false;

  isSaving = false;

  onDeleteClick(): void {
    this.showForm = true;
  }

  onCancelClick(): void {
    this.showForm = false;
  }

  async onSubmit(): Promise<void> {
    if (!this.card.id) {
      throw new Error('Missing id property');
    }

    this.isSaving = true;
    try {
      await cardService.delete(this.card.id);
      this.$emit('deleted', this.card.id);
      this.isSaving = false;
      this.showForm = false;
    } catch {
      this.isSaving = false;
    }
  }
}
