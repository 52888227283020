export interface CardTextPart {
  text: string;
  isMain: boolean;
}

export type CardText = CardTextPart[];

function even(value: number) {
  return (value % 2) === 0;
}

export function stringToCardText(txt: string): CardText {
  const parts = txt.trim().split(/[[\]]/);

  /**
   * should return the result because it's an exception under the rule:
   * it's not in brackets, so it would be a 'context' with the logic below
   */
  if (parts.length === 1) {
    return [{ text: parts[0], isMain: true }];
  }

  // if the first item is empty, it means the string started with a bracket;
  const startedWithMain = parts[0] === '';
  const partsWithoutEmpty = parts.filter((item) => !!item);
  const cardText = partsWithoutEmpty.map((item, index) => ({
    text: item,
    isMain: (startedWithMain ? even(index) : !even(index)),
  } as CardTextPart));

  return cardText;
}

// Test cases will be:
// const txtWithoutBrackets = 'QWE ASD'
// const txtBeginningWithContext = 'qwe asd [QWE ASD] zxc vbn [ZXC VBN]';
// const txtBeginningWithMain = '[QWE ASD] qwe asd [ZXC VBN] zxc vbn';
// const txtBeginningWithSpaces = '   [QWE ASD] qwe asd [ZXC VBN] zxc vbn';
// const txtUsingMultipleSpaces = '  [QWE ASD]     qwe asd  [ZXC  VBN]  zxc vbn';
// const txtOnlyMain = '[QWE ASD]'
